import React from "react";

import Button from "../Button/Button";

import "./ellipsItems.scss";

const EllipsItems = (props) => {
  const { content, itemTitleAsHeading } = props;
  return (
    <div className="ellipsItems-wrapper">
      <div className="decoration">
        <div className="content">
          {content.map((item, index) => {
            return (
              <div
                className={index % 2 !== 0 ? "item" : "item reversed"}
                key={index}
              >
                { item.image &&
                <section>
                  <div className="image-wrapper">
                    <img src={item.image} alt="kep1" loading="lazy"/>
                  </div>
                </section>
                }
                <section>
                  <div className="content-wrapper">
                    {
                      itemTitleAsHeading 
                      ? (<h3>{item.title}</h3>)
                      : (<p className="title">{item.title}</p>)
                    }
                    <div>{item.description}</div>
                    { item.button_text && (item.hook || item.url ) !== undefined && (item.hook ? (
                      <Button
                        variant="contained"
                        background_color={"#5107FF"}
                        text={item.button_text}
                        color={"white"}
                        onClick={() => {
                          let hook = document.getElementById(item.hook);

                          hook.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }}
                      ></Button>
                    ) : (
                      <a href={item.url}>
                        <Button
                          variant="contained"
                          background_color={"#5107FF"}
                          text={item.button_text}
                          color={"white"}
                        ></Button>
                      </a>
                    ))}
                  </div>
                </section>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default EllipsItems;
