import React from "react";
import ReactMarkDown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Button from "../Button/Button";
import "./fullWidthImage.scss";

const FullWidthImage = (props) => {
  const { image, content, color, button_text, onClick } = props;

  return (
    <div
      className="fullWidthImage-wrapper"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: color,
      }}
      loading="lazy"
    >
      <div className="fullWidthImage-body">
        <div className="fullWidthImage-content">
          <h2 className="title center">{content.title}</h2>
          <div className="description center">
            <ReactMarkDown
              rehypePlugins={[rehypeRaw]}
              children={content.description}
            />
            {button_text && (
              <Button
                variant="contained"
                background_color={"#5107FF"}
                text={button_text}
                color={"white"}
                onClick={onClick}
              ></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullWidthImage;
