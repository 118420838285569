import React from "react";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import ReactMarkdown from "react-markdown";
import kep1 from "../../assets/images/ItemsWithImageTitleDescButton/erintesmentes.svg";
import kep2 from "../../assets/images/ItemsWithImageTitleDescButton/eszkozfuggetlen.svg";
import kep3 from "../../assets/images/ItemsWithImageTitleDescButton/felhoalapu.svg";
import "./itemsWithImageTitleDescButton.scss";
import Button from "../Button/Button";
import rehypeRaw from "rehype-raw";

const pictures = [kep3, kep2, kep1];

const ItemsWithImageTitleDescButton = ({ content }) => {
  return (
    <div className="itemsWithImageTitleDescButton-wrapper">
      <LayoutComponent>
        <div className="body-content">
          <div className="items">
            {content.itemWithImage.map((item, index) => {
              return (
                <div className={"item"} key={index}>
                  <div className="image-wrapper">
                    {item.image && (
                      <img
                        src={`${pictures[index]}`}
                        alt="online-szamlazo_image"
                        loading="lazy"
                      />
                    )}
                  </div>
                  <h2 className="center">{item.title}</h2>
                  {/* {item.price && (
                    <div className="price center">{item.price}</div>
                  )} */}
                  <div className="description">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={item.description}
                    />
                  </div>
                  <div className="button-wrapper">
                    <a href={item.button_url}>
                      <Button
                        variant="outlined"
                        background_color={"#5107FF"}
                        text={item.button_text}
                        color={"#5107FF"}
                        hover="hover_purple_white"
                      ></Button>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default ItemsWithImageTitleDescButton;
