import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import ItemTabpanel from "./ItemTabpanel";

import TitleDescCarousel from "../TitleDescCarousel/TitleDescCarousel";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import ReactMarkdown from "react-markdown";

import "./itemsWithTabs.scss";
import TitleDescription from "../TitleDescription/TitleDescription";
import rehypeRaw from "rehype-raw";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 0,
    width: "100%",
  },
  tabs: {
    color: "black",
  },
  tab: {
    backgroundColor: "white",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "black",
    backgroundColor: "#F0F0F0",
    fontSize: theme.typography.pxToRem(15),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const ItemsWithTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    tabDescription,
    panel_title,
    panel_description,
    items,
    contents,
    panel_description_2,
    id,
  } = props;

  return (
    <>
      {tabDescription && (
        <div
          className="tabDescription"
          id={id}
          style={
            tabDescription.primary_color && tabDescription.secondary_color
              ? {
                  background: `radial-gradient(ellipse at bottom, ${tabDescription.secondary_color}, ${tabDescription.primary_color})`,
                }
              : tabDescription.primary_color
              ? { backgroundColor: tabDescription.primary_color }
              : null
          }
        >
          <div className="tabDescription-body">
            <h2>{tabDescription.title}</h2>
            <div className="description">
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={tabDescription.description}
              />
            </div>
            {tabDescription.link && tabDescription.linkURL ? (
              <a to={tabDescription.linkURL}>
                {tabDescription.link}
                <NavigateNextIcon />
              </a>
            ) : null}
          </div>
        </div>
      )}

      {panel_title || panel_description ? (
        <TitleDescription title={panel_title} description={panel_description} />
      ) : null}

      <div className="itemsWithTabs-wrapper" id={id}>
        {items && (
          <div className={classes.root}>
            <AppBar position="static" className={"horizontal-tab"}>
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                centered
                className={classes.tabs}
              >
                {items.map((item, index) => {
                  return (
                    <StyledTab
                      key={index}
                      value={`${index}`}
                      label={item.tabTitle}
                      className={classes.tab}
                      {...a11yProps(`${index}`)}
                    />
                  );
                })}
              </StyledTabs>
            </AppBar>
            <AppBar
              position="static"
              style={{
                height: "200px",
              }}
              className={"vertical-tab-mobile"}
            >
              <StyledTabs
                value={value}
                orientation="vertical"
                onChange={handleChange}
                aria-label="wrapped label tabs example"
                centered
              >
                {items.map((item, index) => {
                  return (
                    <StyledTab
                      key={index}
                      value={`${index}`}
                      label={item.tabTitle}
                      className={classes.tab}
                      {...a11yProps(`${index}`)}
                    />
                  );
                })}
              </StyledTabs>
            </AppBar>
            <div className="tabpanel-wrapper">
              {items.map((item, index) => {
                return (
                  <TabPanel
                    className="tabpanel"
                    value={value}
                    index={`${index}`}
                    key={index}
                    style={{ width: "100%", padding: 0 }}
                  >
                    <ItemTabpanel content={item} />
                  </TabPanel>
                );
              })}
            </div>
          </div>
        )}
        {contents && <TitleDescCarousel content={contents} />}
        {panel_description_2 && (
          <TitleDescription description={panel_description_2} />
        )}
      </div>
    </>
  );
};

export default ItemsWithTabs;
