import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import logo1 from "../../assets/images/CardsAdvantages/adatvedelem_es_tarolas.svg";
import logo2 from "../../assets/images/CardsAdvantages/egyzerukeresheto.svg";
import logo3 from "../../assets/images/CardsAdvantages/mindignaprakesz.svg";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./cardsAdvantages.scss";

const content = [
  {
    title: "Adatvédelem és tárolás",
    description:
      "<div>professzionális, bankbiztonság szintű védelem hackerek ellen</div><div><br/></div><div>több szerveren is eltároljuk adataid másolatát, így egy hirtelen leállás sem jelent gondot</div>",
    logo: logo1,
  },
  {
    title: "Egyszerű kereshetőség",
    description:
      "<div>bármikor visszakeresheted bármely számlád akár a mobilodon</div><div><br/></div><div>könyvelőd megtekintési jogosultsággal hozzáférést kap adataidhoz</div>",
    logo: logo2,
  },
  {
    title: "Mindig naprakész",
    description:
      "<div>automatikusan frissítjük rendszerünk, így neked nem kell szoftverfrissítéssel foglalkoznod</div><div><br/></div><div>élőben frissülnek kereskedelmi adataid és raktárkészleted</div>",
    logo: logo3,
  },
];

const CardsAdvantages = ({ title, background_color }) => {
  return (
    <div className="cardsAdvantages-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          {title && <h2 className="title">{title}</h2>}
          <div className="cards">
            {content.map((item, index) => {
              return (
                <div className="card" key={index}>
                  <img src={item.logo} alt="logo" loading="lazy" />
                  <p className="titl">{item.title}</p>
                  <div className="description">
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={item.description}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default CardsAdvantages;
