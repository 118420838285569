import React from "react";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./titleCards.scss";

const TitleCards = ({ content }) => {
  return (
    <div className="titleCards-wrapper">
      <LayoutComponent background_color={"#F7F7FA"}>
        <div className="titleCards-body-content">
          {content.title && <h2 className="titleCards-title">{content.title}</h2>}
          <div className="titleCards-cards">
            {content.kinekAjalnjukItem.map((card, index) => {
              return (
                <div
                  className={card.highlighted ? "card highlighted" : "card"}
                  key={index}
                >
                  <div className="image-wrapper">
                    <img
                      src={`${process.env.GATSBY_CMS_URL}${card.image.url}`}
                      alt={card.image_alt}
                      loading="lazy"
                    />
                  </div>
                  <p>{card.title}</p>
                  {card.description && (
                    <div className="desc">{card.description}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default TitleCards;
