import React from "react";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";
import FirstComponent from "../../components/FirstComponent/FirstComponent";
import ItemsWithImageTitleDescButton from "../../components/ItemsWithImageTitleDescButton/ItemsWithImageTitleDescButton";
import PaymentMethods from "../../components/PaymentMethods/PaymentMethods";
import IllustrationDesc from "../../components/IllustrationDesc/IllustrationDesc";
import EllipsItems from "../../components/EllipsItems/EllipsItems";
import TitleCards from "../../components/TitleCards/TitleCards";
import TitleDescription from "../../components/TitleDescription/TitleDescription";
import DownloadNow from "../../components/DownloadNow/DownloadNow";
import CardsAdvantages from "../../components/CardsAdvantages/CardsAdvantages";
import RegisterAndDownload from "../../components/RegisterAndDownload/RegisterAndDownload";
import TitleDescImg from "../../components/TitleDescImg/TitleDescImg";
import hogyall_a_raktarkeszlet from "../../assets/images/TitleDescImg/hogyall_a_raktarkeszlet.svg";
import ImageNextToDesc from "../../components/ImageNextToDesc/ImageNextToDesc";
import ajanlas_image from "../../assets/images/ImageNextToDesc/ajanlas.svg";
import BlueCardComponent from "../../components/BlueCardComponent/BlueCardComponent";
import FullWidthImage from "../../components/FullWidthImage/FullWidthImage";
import tobbmintszamlazo from "../../assets/images/FullWidthImage/többmintszamlazo.jpg";
import ItemsWithTabs from "../../components/ItemsWithTabs/ItemsWithTabs";
import QuoteComponent from "../../components/QuoteComponent/QuoteComponent";
import koltseghatekony from "../../assets/images/FullWidthImage/kolseghatekony_kep.jpg";
import szamlanyomtatok from "../../assets/images/FullWidthImage/szamlanyomtatok_kép.jpg";
import "./onlineSzamlazo.scss";

import kep1 from "../../assets/images/EllipsItems/qr.svg";
import kep2 from "../../assets/images/EllipsItems/ellenorizd_tavolrol_afrorgalmad.svg";
import kep3 from "../../assets/images/EllipsItems/felejtseel_a_papirszamlatombot.svg";

import illustrationDescImage from "../../assets/images/illustrationDesc/tarold-egyhelyen.png";
import illustrationDesc_Logo1 from "../../assets/images/illustrationDesc/e-szamlak.svg";
import illustrationDesc_Logo2 from "../../assets/images/illustrationDesc/elektronikus_fizetes.svg";
import illustrationDesc_Logo3 from "../../assets/images/illustrationDesc/online_penztargepi_adatok.svg";

const OnlineSzamlazo = (props) => {
  const {
    firstComponent,
    FullWidthImageData,
    itemsWithImage,
    SEO,
    products
  } = props.pageContext.content;

  const { paymentMethods, kinekAjanljuk, registerAndDownload } =
    props.pageContext;

  const { items, tabs } = props.pageContext.extra;
  const location_href = props.location.pathname;

  const illustrationDesc = {
    descImage: illustrationDescImage,
    title: "Tárold egy helyen az összes kereskedelmi adatod a CMO rendszerrel!",
    description: `A CMO kereskedelmi rendszert azért hoztuk létre, mert korábban túl bonyolult volt egyszerre három-négy helyen nyomon követni a bevételeink és kiadásaink alakulását. Ma elég egyetlen pillantást vetni az applikációra, és pontosan tudjuk, hogy áll az aktuális mérlegünk!`,
    cards: [
      {
        title: "E-számlák",
        logo: illustrationDesc_Logo1
      },
      {
        title: "Online pénztárgépi adatok",
        logo: illustrationDesc_Logo3
      },
      {
        title: "Elektronikus fizetési adatok",
        logo: illustrationDesc_Logo2
      }
    ]
  }
  const ellipseItemContent = [
    {
      image: kep3,
      title: "Felejtsd el a papír számlatömböt! Elő a mobillal!",
      description:
        "Soha többé nem fog előfordulni, hogy otthon felejted a számlatömböd, vagy elfelejted leadni a könyvelődnek az előző havi számláidat. Ha a mobilod nálad van, bármikor ki tudsz állítani egy számlát, és azt a könyvelőd is azonnal látni fogja.",
      button_text: "Letöltés",
      url: null,
      hook: "registerAndDownload",
    },
    {
      image: kep2,
      title: "Ellenőrizd távolról a forgalmad!",
      description:
        "Nem kell egész nap a pénztárgép mellett ülnöd ahhoz, hogy lásd, hogy alakul az üzleted aznapi forgalma. Bérelj tőlünk online pénztárgépet, vagy kapcsold össze a meglévő pénztágéped a CMO számlázó programmal, és kövesd nyomon üzleted forgalmát élőben!",
      button_text: "Pénztárgép bérlés",
      url: "/online-penztargep",
      hook: null,
    },
    {
      image: kep1,
      title: "Érintésmentes fizetés QR-kóddal",
      description:
        "Online számlázó programunk egyik kiegészítő szolgáltatása a bankkártya- és érintésmentes elektronikus fizetés. Ha nálad is csak elvétve fizetnek elektronikusan, és ezért nem éri meg egy bankkártyaleolvasó terminált fenntartani, ezt a rendszert pont neked találtuk ki!",
      button_text: "Érintésmentes fizetési lehetőségeink",
      url: "/qr-kodos-fizetes",
      hook: null,
    },
  ];
  return (
    <div className="vendeglatas-wrapper">
      <LayoutHeaderFooter
        header_background_color={"#FF6376"}
        header_secondary_color={"#FF9FA6"}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
        SEO_image={SEO.image}
        SEO_location={location_href}
      >
        <FirstComponent
          primary_color={"#FF6376"}
          secondary_color={"#FF9FA6"}
          content={{ ...firstComponent, products }}
          image={firstComponent.image.url}
          image_mobile={firstComponent.image_mobile.url}
          subpage={true}
          big_image
          subtitle={"<div>A legokosabb online számlázó program a piacon</div>"}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <FullWidthImage
          image={koltseghatekony}
          content={FullWidthImageData}
          color={"white"}
        />
        <ItemsWithImageTitleDescButton content={itemsWithImage} />
        <PaymentMethods
          title={
            "Fizetési lehetőségek, amivel a legtöbb CMO rendszer kompatibilis"
          }
          content={paymentMethods}
        />
        <IllustrationDesc  content={illustrationDesc} />
        <EllipsItems content={ellipseItemContent}/>
        <TitleCards content={kinekAjanljuk} />
        <TitleDescription
          background_color="#F7F7FA"
          title={`Nem elérhető egy funkció, ami számodra hasznos lenne?`}
          description={`Írj egy levelet az ügyfélszolgálatunknak, és a csapatunk 48 órán belül megvizsgálja a lehetőségét annak, hogy lefejlesszük mindenki számára elérhető módon a funkciót.`}
        />
        <QuoteComponent background_color={"#363636"} />
        <DownloadNow
          content={{
            title: "Próbáld ki te is kockázatmentesen!",
            primary_color: "#8C16FF",
            secondary_color: "#5A20FC",
            button_text: "Letöltés",
            hover: "hover_white_purple",
          }}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <TitleDescription
          title={`Nézd meg, hogy működik élesben a CMO számlázója:`}
          description={`<iframe width="560" height="315" src="https://www.youtube.com/embed/O_OYg5UGNkQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
        />
        <ItemsWithTabs
          id="csomagajánlataink"
          panel_title={"Csomagajánlataink"}
          items={tabs[0].tabItems}
        />
        <ItemsWithTabs
          id="hordozhato-szamlanyomtatok"
          panel_title={"Hordozható számlanyomtató"}
          panel_description={
            "Változó a munkavégzésed helyszíne? Hordozható számlanyomtatóink kisebbek, mint a pénztárcád! Nézd meg a kínálatot!"
          }
          contents={items}
          panel_description_2={
            "A CMO által ajánlott számlanyomtatók maximálisan kompatibilisek a CMO számlázójával, és az ajánlott érintésmentes fizetési moduljainkkal."
          }
        />
        <FullWidthImage
          image={szamlanyomtatok}
          content={{
            description:
              "A számlanyomtatót értékesítő partnereinken keresztül tudod beszerezni. Amennyiben kiválasztottad a neked megfelelő eszközt, egy gyors regisztrációt követően máris a megfelelő partnerhez&nbsp;irányítunk.",
          }}
          button_text={"Regisztrálok"}
          color={"white"}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <ImageNextToDesc
          image={ajanlas_image}
          color={"white"}
          title={"Ajánlj bennünket,"}
          subtitle={
            "és cserébe egy évig ingyen használhatod a CMO azonnali fizetési rendszerét!"
          }
          description={
            "<div>Hívd meg ismerőseid a mobilapplikáció Beállítások / Promóciók menüpontjából, és gyűjts 10 regisztált felhasználót!</br>Hálánk jeléül cserébe 12 hónapig ingyenesen használhatod az azonnali fizetés szolgáltatásunk, így spórolunk neked 19200 Ft + ÁFA-t!</div>"
          }
          primary_color={"#04BEB2"}
          secondary_color={"#0FD9C1"}
          button_text={"Regisztrálok a CMO online számlázó rendszerébe!"}
          footNote={
            "Az online számlázó rendszer letöltése és használata ingyenes."
          }
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <CardsAdvantages
          background_color={"#F7F7FA"}
          title={"A felhőalapú szolgáltatás 3 legnagyobb előnye"}
        />
        <RegisterAndDownload content={registerAndDownload} />
        <TitleDescImg
          title={"Hogy áll az aktuális raktárkészleted? - hamarosan (2022.04.01.) "}
          description={
            "Hamarosan a CMO-val is ellenőrizheted!<br/><br/>Ha egy vállalatirányítási ERP rendszer még túl sok neked, de szeretnél lépést tartani a digitális korral, a CMO online számlázó készletkezelő funkcióját imádni fogod!<br/><br/>Két perc alatt létrehozhatsz egy belső nyilvántartást, mely a kibocsájtott számláid alapján ettől fogva automatikusan frissül. Így mindig tudni fogod, pontosan hogy áll a készleted.</br><br/>Funkció várható érkezése: 2022. április."
          }
          image={hogyall_a_raktarkeszlet}
        />
        <DownloadNow
          content={{
            title: "Próbáld ki, és regisztrálj most a CMO rendszerébe!",
            primary_color: "#8C16FF",
            secondary_color: "#5A20FC",
            button_text: "Regisztrálok",
            hover: "hover_white_purple",
          }}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
        <BlueCardComponent
          background_color={"#F7F7FA"}
          title={"NAV online adatszolgáltatás"}
          subtitle={"100% biztonság"}
          description={
            "<div>2021. április 1 óta szigorodott a számlaadással kapcsolatos adatszolgáltatási jogszabály, melynek értelmében ma már minden magánszemély vagy nem adóalany (pl. társasház vagy egyesület) részére kiállított számlát is továbbítani kell a NAV felé.<br/><br/>A jogszabály az elektronikus és a papír alapon kiállított számlákra egyaránt érvényes, ezért mi eleve úgy alkottuk meg a rendszerünket, hogy a regisztrációkor egyből össze tudd azt kapcsolni a NAV Online Számla rendszerével.<br/><br/>Így miután befejezted a regisztrációt (max. 10 perc az egész), azonnal a jogszabályoknak megfelelően kezdesz el számlákat kibocsájtani!</div>"
          }
        />
        <FullWidthImage
          image={tobbmintszamlazo}
          content={{ title: "Több mint számlázó.\nEz a CMO." }}
          button_text={"Regisztrálok"}
          color={"white"}
          onClick={() => {
            let item = document.getElementById("registerAndDownload");
            item.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        />
      </LayoutHeaderFooter>
    </div>
  );
};

export default OnlineSzamlazo;
