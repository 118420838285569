import React from "react";
import LayoutComponent from "../LayoutComponent/LayoutComponent";
import "./illustrationDesc.scss";
import CMOButton from "../Button/Button";
import { navigate } from "gatsby-link";

const IllustrationDesc = (props) => {
  const { content } = props;
  return (
    <div className="illustrationDesc-wrapper">
      <LayoutComponent background_color={"#E3E3E3"}>
        <div className="body-content">
          <section className="image-wrapper">
            <img src={content.descImage} alt="illustration_cmo" loading="lazy" />
          </section>
          <section className="content">
            <h2 className="illustration-desc-title">
              {content.title}
            </h2>
            <div className="illustration-desc-description center">
              {content.description}
            </div>
            <div className="illustration-desc-icons">
            { 
              content.cards?.map((itm,idx) => {
               return ( 
                <div className="icon" key={idx}>
                  <img src={itm.logo} title={itm.title} loading="lazy"/>
                  <div className="">{itm.title}</div>
                </div>
                );
               }) }
            </div>
            {
              content.button ? 
              <CMOButton
                  onClick={() => { navigate( content.button.link ); }}
                  variant="contained"
                  background_color={"#5107ff"}
                  text={content.button.text}
                  color={"white"}/>
              : ""
            }
          </section>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default IllustrationDesc;
