import React from 'react';
import TitleDescCarousel from '../TitleDescCarousel/TitleDescCarousel';
import './itemTabpanel.scss';

const ItemTabpanel = ({ content }) => {
  return (
    <div className="itemTabpanel-wrapper">
      <TitleDescCarousel content={content.items} />
    </div>
  );
};

export default ItemTabpanel;
